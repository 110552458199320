.card-swiper {
  border: 1px solid rgba(219, 219, 219, 0.692);
  width: 160px;
}

.row-fare {
  padding: 10px;
  width: 160px;
  height: 80px;
  text-align: center;
}

.row {
  text-align: center;
  font-weight: 600;
  padding: 5px;

}

.divider {
  border-top: 1px solid #ccc;
}

.card-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  overflow: hidden;
  color: black;
  font-weight: 600;
  position: relative;
}

.button-container {
  position: absolute;
  top: -3rem;
  right: 0;
  padding: 10px;
}

.round-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--primCol);
  background-color: #6cc7eeb9;
  color: var(--primCol);
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2px;
}


.row:hover {
  cursor: pointer;
  background-color: #6cc7eeb9;
}


@media (max-width:767px) {
  .swiper-card {
    display: none;
  }

}